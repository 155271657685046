import React from 'react'
import { Helmet } from 'react-helmet'

import Layout from '../components/layout'
import LeafletMap from '../components/map'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEuroSign, faListUl, faMapMarkedAlt, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'
import { faClock } from '@fortawesome/free-regular-svg-icons'

const LeCentre = () => {
    const position = [49.79717867196202, 5.533661244135341]

    return (
        <>
            <Helmet>
                <title>Educa'dog : Centre d'éducation positive à Léglise - Le centre</title>
                <descrtiption>Educa'dog est un centre d'éducation canine positive situé chemin du Stria à Léglise, en province de Luxembourg. Nous n'utilisons que les méthodes positive et respectueuses du chien.</descrtiption>
            </Helmet>
            <Layout>
                <section id="le-centre">
                    <h1 className="page-title fredoka">
                        Le centre
                    </h1>
                    <div className="grid">
                        <section className="block">
                            <h2 className="block-title balsamiq">
                                Les cours et les horaires
                            </h2>
                            <p>
                                Les cours se divisent en trois groupes et durent 45 minutes :<br />
                            </p>
                            <ul>
                                <li>
                                    <strong>9h - 9h45 :</strong> Chiots à partir de 2 mois et demi jusqu’à 6 mois
                                </li>
                                <li>
                                    <strong>10h - 10h45 :</strong> Chiots à partir de 6 mois et chiens adultes, tous de petite taille ou timides.
                                </li>
                                <li>
                                    <strong>11h - 11h45 :</strong> Chiots à partir de 6 mois et chiens adultes, tous de grande taille ou sûrs d'eux. 
                                </li>
                            </ul>
                            <p>
                                <strong>Attention, pour les adultes, nous ne pouvons accepter que des chiens adultes qui doivent revoir les bases d’éducation. Nous n’accueillons pas de chien expérimenté.</strong>
                            </p>
                            <p>
                                Notre centre n'a pas pour but de faire de vos chiens des bêtes d'éducation, mais bien des chiens bien intégrés et heureux en famille.<br />
                                Dans un souci d'organisation, seuls les chiens sans problème comportemental (agressivité, etc) seront acceptés dans ces trois groupes, afin qu'ils puissent tous profiter d'un moment en liberté dans le terrain.<br />
                                <strong>Nous réfléchissons d'ores et déjà à prévoir une séance supplémentaire, en groupe réduit, pour les chiens à difficulté.</strong>
                            </p>
                            <FontAwesomeIcon icon={faClock} className="block-icon" />
                        </section>
                        <section className="block black">
                            <h2 className="block-title balsamiq">
                                Tarifs
                            </h2>
                            <p>
                                <strong>Cotisation annuelle :</strong> 35€<br />
                                <strong>Cours (à l’unité) :</strong> 3€<br />
                                <strong>Abonnement de 10 cours + 1 gratuit :</strong> 30€<br />
                            </p>
                            <p>
                                Le premier cours est gratuit.
                            </p>
                            <FontAwesomeIcon icon={faEuroSign} className="block-icon" />
                        </section>
                        <section className="block primary">
                            <h2 className="block-title balsamiq">
                                Conditions d’accès
                            </h2>
                            <p>
                                Afin de pouvoir vous inscrire avec votre chien, celui-ci doit :<br />
                            </p>
                            <ul>
                                <li>
                                    Être identifié par puce électronique et avoir un passeport européen
                                </li>
                                <li>
                                    Être au moins primo vacciné (pour les plus jeunes) ou vacciné (pour les chiots de plus de trois mois)
                                </li>
                                <li>
                                    Être vacciné contre la toux du chenil (conseillé en intra-nasal pour les plus jeunes, car l’effet est plus rapide)  
                                </li>
                                <li>
                                    Etre en ordre d'assurance familiale RC
                                </li>
                            </ul>
                            <FontAwesomeIcon icon={faListUl} className="block-icon" />
                        </section>
                        <section className="block primary">
                            <h2 className="block-title balsamiq">
                                Le terrain
                            </h2>
                            <p>
                                Nous vous accueillons sur un terrain de près de 600m2, divisé en trois parties :
                            </p>
                            <ul>
                                <li>
                                    Un sas d’entrée de 30m²
                                </li>
                                <li>
                                    Un enclos de mise en liberté de 130m²
                                </li>
                                <li>
                                    Deux zones de cours de 450m² et 130m²
                                </li>
                            </ul>
                            <FontAwesomeIcon icon={faMapMarkedAlt} className="block-icon" />
                        </section>
                        <section className="block black">
                            <h2 className="block-title balsamiq">
                                Accès
                            </h2>
                            <p>
                                Nous sommes situés au Chemin de Stria à <strong>Léglise</strong>.<br />
                                Lorsque vous passez devant l’Administration Communale, prenez la petite rue qui longe le bâtiment sur la gauche et gardez votre gauche.<br />
                                Un parking est à votre disposition en face de l’école. <br />
                                Après le parking, marchez encore 100 mètres. Vous voici arrivés!
                            </p>
                            <p>
                                Merci de <strong>ne pas laisser vos déchets sur le parking et le chemin</strong> menant à Educa’Dog et de <strong>ramasser les déjections de votre chien</strong>. 
                            </p>
                            <FontAwesomeIcon icon={faMapMarkerAlt} className="block-icon" />
                        </section>
                        {typeof window !== 'undefined' && 
                            <section className="block map-container">
                                <LeafletMap></LeafletMap>
                            </section>
                        }
                    </div>
                    {/* <section className="block">
                        <h2 className="block-title balsamiq">
                            Contact
                        </h2>
                        <p>
                            Vous pouvez nous joindre à tout moment via notre page Facebook, (Educa’Dog - Centre d’éducation positive - Léglise) ou par email, à l’adresse: <a href="mailto:info@educadog.be">info@educadog.be</a><br />
                            Aussi, le numéro <strong>+32 478 099 164</strong> est à votre disposition <strong>à certains moments</strong>.<br />
                            Privilégiez les <strong>emails</strong> et <strong>messages via Facebook</strong>. 
                        </p>
                    </section> */}
                </section>
            </Layout>
        </>
    )
}

export default LeCentre
