import React, { useEffect } from 'react'

import marker from '../images/marker.svg'

const LeafletMap = () => {
    const position = [49.79717867196202, 5.533661244135341]

    useEffect(() => {
        const L = require('leaflet')
        const icon = new L.icon({
            iconUrl: marker,
            iconSize: [38, 48]
        })

        let map = L.map('map').setView(position, 13);

        L.tileLayer('https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png', {
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
            subdomains: 'abcd',
            maxZoom: 19
        }).addTo(map);

        L.marker(position, { icon: icon }).bindPopup('Educa\'dog asbl<br />Chemin du Stria<br />6860 Léglise').addTo(map);
    });

    return (
        <div id="map" className="map"></div>
    )
}

export default LeafletMap